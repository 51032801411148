// import React from 'react'
// import styled from "styled-components"
// import {media} from "utils/Media"

// export default function Winner() {
//   return (
//     <Wrapper>
//     <div>
//         <h3 className="h2"> TONY<sup>&reg;</sup> AWARD NOMINEE <br />5 OUTER CRITICS CIRCLE NOMINATIONS <br />
// WINNER! BEST NEW MUSICAL
// </h3>
// <br />
// <br />
// <h3 className='h2'>TONY® AWARD NOMINEE <br />
// BEST FEATURED ACTOR - ROGER BART <br />
// BEST SCENIC DESIGN - TIM HATLEY AND FINN ROSS
// </h3>
// <br />
// <h3 className='h2'>
// 5 OUTER CRITICS CIRCLE NOMINATIONS<br />OUTSTANDING LEAD PERFORMER - CASEY LIKES<br />OUTSTANDING FEATURED PERFORMER - ROGER BART<br />OUTSTANDING LIGHTING DESIGN - TIM LUTKIN AND HUGH VANSTONE&nbsp; OUTSTANDING SOUND DESIGN - GARETH OWEN<br />OUTSTANDING VIDEO/PROJECTIONS - FINN ROSS
// </h3>
//         {/* <p className="color-orange">Olivier Awards 2022 | WhatsOnStage Awards 2022 | Broadway World Awards 2022</p> */}
//     </div>
//     <div className='mt-8'>
//         <h3 className="h2 mb-0 black">WINNER! BEST NEW MUSICAL</h3>
//         <h4 className=" color-orange ">Olivier Awards 2022 | WhatsOnStage Awards 2022 | Broadway World Awards 2022</h4>
//     </div>
// </Wrapper>
//   )
// }

import React, { useRef } from "react";
import Slider from "react-slick";
import styled from "styled-components";
import { media } from "utils/Media";
import { graphql, useStaticQuery } from "gatsby";
import nextImg from "../../../images/icons/next.svg";
import star from "../../../images/icons/star.svg";

const Wrapper = styled.div`
  background: black;
  text-align: center;
  padding: 2rem 0 0.5rem 0;
  div {
    width: 100%;
    color: white;
    margin: 0 auto;
    background: black;
    /* max-width: 1000px; */
  }
  h3,
  h4 {
    color: white;
    font-weight: black!important;
    font-family: ${(props) => props.theme.font.family.black}!important;
  }

  @media ${media.md} {
    padding: 2rem 0 1rem 0;
  }
  p {
    margin: 0 !important;
  }
  strong {
    display: block;
    color: white;
    @media ${media.lg} {
      font-size: 2.2rem;
    }
  }
  .bold {
    font-weight: 800;
  }
  h5 {
    color: white;
  }
  .mt-8 {
    margin-top: 4rem;
  }

  .font-black {
    font-weight: 900;
  }
  .h1 {
    /* font-size: 2.5rem; */
  }
  .slick-dots li 
  .slick-dots li button:before {
    font-size: 12px!important;
  }
  .slick-dots li button:before {
    background: #fff;
  }
  .slick-dots li.slick-active button:before {
    background: #fff!important;
  }
  .slick-dots li.slick-active button:before {

  }
`;

const ReviewSliderStyles = styled.section`
  width: 100%;
  position: relative;
  background-color: #000;
  padding: 2rem 0 1rem 0;
  @media ${media.lg} {
    padding: 3rem 0 4rem 0;
  }
  .r-s {
    max-width: 1400px;
    width: 90%;
    margin: 0 auto;
    text-align: center;
    padding-top: 2rem;
    &--wrapper {
      position: relative;
      .prevBtn,
      .nextBtn {
        width: 30px;
        position: absolute;
        top: 31%;
        @media ${media.sm} {
          width: 45px;
        }
      }
      .prevBtn {
        left: 0;
        transform: scaleX(-1);
        z-index: 5;
      }
      .nextBtn {
        right: 0;
      }
    }
  }
`;
const Lines = styled.div`
  position: absolute;
  z-index: 1;
  background-color: #ff0000;
  height: 2px;
  width: 100vw;
  top: 1rem;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  left: 0;
  &::before,
  &::after {
    content: "";
    position: absolute;
    right: 0;
    z-index: 9;
    background-color: #ff7503;
    height: 2px;
    width: 98%;
    top: 0.4rem;
  }
  &::after {
    width: 96%;
    top: 0.85rem;
    background-color: #ffd200;
  }
`;
const settings = {
  slidesToShow: 1,
  dots: true,
  arrows: false,
  swipe: true,
  swipeToSlide: true,
  adaptiveHeight: true,
  autoplay: true,
  speed: 1000,
  autoplaySpeed: 4000,
  cssEase: "linear"
};

const Winner = () => {
  const sliderRef = useRef();

  return (
    <ReviewSliderStyles>
      <div className="r-s">
        <Lines />
        <div className="r-s--wrapper">
          {/* <PrevButton prev={prev} /> */}
          <Slider {...settings} ref={sliderRef} className="awardsSlider">
            {/* <Wrapper>
              <h3 className="h1">TONY AWARD<sup>&reg;</sup> NOMINEE</h3>
              <h3>
                BEST FEATURED ACTOR – ROGER BART <br />
                BEST SCENIC DESIGN – TIM HATLEY AND FINN ROSS
              </h3>
            </Wrapper> */}
            {/* <Wrapper>
              <h3 className="h1">
              5 OUTER CRITICS CIRCLE NOMINATIONS
              </h3>
              <h3>OUTSTANDING LEAD PERFORMER – CASEY LIKES<br />OUTSTANDING FEATURED PERFORMER – ROGER BART<br />OUTSTANDING LIGHTING DESIGN – TIM LUTKIN AND HUGH VANSTONE&nbsp; OUTSTANDING SOUND DESIGN – GARETH OWEN<br />OUTSTANDING VIDEO/PROJECTIONS – FINN ROSS</h3>
            </Wrapper> */}
            <Wrapper>
              <div className="">
                {" "}
                <h3 className="h1 mb-0 black">WINNER! BEST NEW MUSICAL</h3>{" "}
                <h4 className=" color-orange">
                  Olivier Awards 2022 | WhatsOnStage Awards 2022 | Broadway
                  World Awards 2022
                </h4>{" "}
              </div>
            </Wrapper>
          </Slider>
          {/* <NextButton next={next} /> */}
        </div>
      </div>
    </ReviewSliderStyles>
  );
};

export default Winner;

// const PrevButton = ({ prev }) => (
//     <div className="prevBtn" onClick={() => prev()}>
//         <img src={nextImg} alt="" />
//     </div>
// )
// const NextButton = ({ next }) => (
//     <div className="nextBtn" onClick={() => next()}>
//         <img src={nextImg} alt="" />
//     </div>
// )
