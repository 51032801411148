import React, {Component} from 'react';
import AudioPlayer from 'components/shared/AudioPlayer';
import styled, { css } from "styled-components"

const Content = styled.div`
    text-align: center;
    background-color: white;
    padding: 0;
    
    h3 {
        color: black;
    }
    
    ${props => props.$inverse && css`
         background-color: black;
         
          h3 {
            color: white;
        }
        
    `}
`

class AudioBlock extends Component {
    constructor(props) {
        super(props);

        this.state = {
            stopped: false
        }

        this.stopMusic = this.stopMusic.bind(this);
    }

    componentDidUpdate(prevProps,prevState) {
        if (prevProps.musicStopped !== this.props.musicStopped) {
            if (this.props.musicStopped) {
                this.setState({
                    stopped: true
                }, () => {
                    this.props.stopMusic(true);
                })
            } else {
                this.setState({
                    stopped: false
                }, () => {
                    this.props.stopMusic(false);
                })
            }
        }

    }

    componentWillUnmount() {
        this.setState({
            stopped: true
        })
    }

    stopMusic = (stopped) => {
        this.props.stopVideo(!stopped);
        this.props.stopMusic(false);
    }

    stopVideo = (stopped) => {
        this.props.stopMusic(false);
    }

    render() {
        return (
            <Content {...this.props} className="py-5">
                <h3 className="h2 mb-0">
                    {this.props.title}
                </h3>

                <div className="videos">
                    <AudioPlayer stopMusic={this.stopMusic} stopVideo={this.stopVideo} musicStopped={this.state.stopped}/>
                </div>
            </Content>
        );
    }
}

export default AudioBlock