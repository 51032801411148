import React, { Component } from "react";
import styled from "styled-components";
import { graphql, StaticQuery } from "gatsby";
import { media } from "utils/Media";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import FluxCapacitatorAnimation from "components/Animations/FluxCapacitatorAnimation";
import Counter88Animation from "components/Animations/Counter88Animation";
import StyledButton from "components/shared/StyledButton";
import june from "images/new-york/june.png";

const Content = styled.div`
  position: relative;
  margin: 0 auto;
  text-align: center;
  .broadway-block {
    margin: 2rem 0;
    .smaller {
      font-size: 25px;
      margin-bottom: 1rem;
    }
    p {
      margin-bottom: 0rem;
      font-weight: 800;
      font-size: 40px;

      font-family: ${(props) => props.theme.font.family.black};
      font-weight: 900;

      @media ${media.lg} {
        font-size: 50px;
      }
      @media ${media.xl} {
        font-size: 85px;
      }
    }
    .wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2rem;
    }
    
    .gatsby-image-wrapper {
      margin: 2rem 0;
      max-width: 150px;
      @media ${media.lg} {
        max-width: 200px;
      }
      @media ${media.xl} {
        max-width: 250px;
      }
    }
    .secondImage {
      margin: 2rem 0;
      max-width: 200px;
      @media ${media.lg} {
        max-width: 350px;
      }
      @media ${media.xl} {
        max-width: 350px!important;
      }
    }
  }
  .title-wrapper {
    width: 90%;
    margin: 0 auto;
    max-width: 1350px;
  }

  .june {
    max-width: 100%;
    width: 200px;
    @media ${media.md} {
      width: 350px;
    }
  }

  h3 {
    text-transform: uppercase;
  }
`;
const MartyDoc = styled.div`
  position: relative;
  width: 100%;
  text-align: center;

  @media ${media.lg} {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    .left {
      .gatsby-image-wrapper {
        width: 100%;
      }
    }
  }

  .left,
  .right {
    width: 100%;
    position: relative;
    z-index: 2;

    @media ${media.lg} {
      width: 50%;
    }
  }

  .left {
    @media ${media.lg} {
      padding-bottom: 3rem;
    }
  }

  .inner {
    padding: 0 1rem;
    max-width: 500px;
    margin: 0 auto;
  }

  .stripe {
    width: 100%;
    height: 300px;
    z-index: 1;
    background-color: black;
    position: absolute;
    left: 0;
    bottom: 0;

    @media ${media.sm} {
      height: 340px;
    }

    @media ${media.md} {
      height: 400px;
    }

    @media ${media.lg} {
      height: 190px;
    }

    @media ${media.xl} {
      height: 200px;
    }
  }
`;

class FluxBlock extends Component {
  render() {
    return (
      <Content className="mt-3 mt-md-5">
        <h3 className="h1">Now on Broadway</h3>
        {/* <img className="june mt-md-2 mb-2 mb-md-4" src={june} alt="JUNE 30. 2023"/> */}

        <FluxCapacitatorAnimation />
        <MartyDoc>
          <div className="left d-none d-lg-block">
            <GatsbyImage
              image={getImage(this.props.data.martyDocBgLg)}
              alt=""
              title=""
              className="img-fluid d-none d-lg-block"
            />
          </div>
          <div className="right">
            <div className="inner">
              {/* <h4 className="mb-lg-4 black">TAKE AN ELECTRIFYING RIDE BACK IN TIME WITH THE OLIVIER AWARD-WINNING BEST NEW MUSICAL.</h4> */}

              <p className="mb-lg-4">
                Welcome to Hill Valley! When Marty McFly finds himself
                transported back to 1955 in a time machine built by the
                eccentric scientist Doc Brown, he accidentally changes the
                course of history. Now he’s in a race against time to fix the
                present, escape the past and send himself... back to the future.
              </p>

              <p className="mb-lg-4">
                Set your destination time, New York and get ready to make
                musical theater history.
              </p>
              <p className="mb-lg-4">Get your tickets yesterday!</p>

              <StyledButton
                $hide
                className="mt-2 mb-4 mx-auto"
                $inverse
                target="_blank"
                rel=" noopener"
                href={this.props.booking}
              >
                GET TICKETS
              </StyledButton>

              <div className="d-none d-lg-block mb-lg-2">
                <Counter88Animation />
              </div>
            </div>
          </div>
          <div className="left d-lg-none position-relative">
            <GatsbyImage
              image={getImage(this.props.data.martyDocBgXs)}
              alt=""
              title=""
              className="img-fluid d-sm-none"
            />
            <GatsbyImage
              image={getImage(this.props.data.martyDocBgSm)}
              alt=""
              title=""
              className="img-fluid d-none d-sm-block d-lg-none"
            />
            <div className="d-lg-none">
              <Counter88Animation />
            </div>
          </div>
          <div className="stripe" />
        </MartyDoc>
      </Content>
    );
  }
}

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        martyDocBgXs: file(relativePath: { eq: "new-york/ny-new-image.png" }) {
          childImageSharp {
            gatsbyImageData(width: 575)
          }
        }
        martyDocBgSm: file(relativePath: { eq: "new-york/ny-new-image.png" }) {
          childImageSharp {
            gatsbyImageData(width: 991)
          }
        }
        martyDocBgLg: file(relativePath: { eq: "new-york/ny-new-image.png" }) {
          childImageSharp {
            gatsbyImageData(width: 1428)
          }
        }
        testImage: file(
          relativePath: { eq: "2024TFCA.png" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 1028)
          }
        }
      }
    `}
    render={(data) => <FluxBlock booking={props.booking} data={data} />}
  />
);
