import React, {Component} from 'react';
import {Container} from "reactstrap"
import styled from "styled-components";
import {graphql, StaticQuery} from "gatsby";
import {media} from "utils/Media"
import ReactPlayer from "react-player"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import StyledButton from "components/shared/StyledButton"
import HeaderShape from "images/HomePage/header-shape.svg"
import playIcon from 'images/icons/btn-play.svg'
import closeIcon from 'images/icons/close.svg'

const Content = styled.div`
    position: relative;
    text-align: center;
    
    @media ${media.lg} {
        margin: 2rem auto 0;
    }
    
    .play-icon {
        width: 40px;
    }
    
    .close-icon {
        position: absolute;
        z-index: 99;
        top: 10px;
        right: 10px;
        width: 30px;
        display: none;
        
        @media ${media.md} {
            width: 80px;
        }
        
        &.playing {
            display: block;
        }
    }
`

const ColouredShape = styled.div`
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    
    @media ${media.md} {
        background: url("${HeaderShape}") center center no-repeat;
        background-size: 100%;
        height: 100px;
    }
    
    h3 {
        text-align: center;
        width: 345px;
        
        @media ${media.lg} {
             width: 100%;
             max-width: 680px;
         }
    }
`

class GreatScottBlock extends Component {
    constructor(props) {
        super(props);

        this.state = {
            playing: false,
            stopped: false
        };
    }

    componentDidUpdate(prevProps,prevState) {
        if (prevProps.videoStopped !== this.props.videoStopped) {

            if (this.props.videoStopped && !this.props.musicStopped) {
                this.setState({
                    playing: false
                })
            }
            if (!this.props.videoStopped && this.props.musicStopped) {
                this.setState({
                    playing: true
                })
            }
        }
    }

    onVideoPlay = (e) => {
        this.props.stopMusic(true)
        this.props.stopVideo(false);
        setTimeout(() => {
            this.setState({
                playing: true
            })
        }, 300)
    }

    onVideoStop = (e) => {
        this.props.stopMusic(false)
        this.props.stopVideo(true);
        setTimeout(() => {
            this.setState({
                playing: false
            })
        }, 300)
    }

    render() {
        return (
            <Content>
                <Container className="mt-3 mt-lg-5">
                    <ColouredShape>
                        <h3 className="mb-3 mb-md-0 pt-3 pt-xl-0">
                            {this.props.title ?
                                this.props.title
                                :
                                "GREAT SCOTT! CHECK OUT OUR TRAILER."
                            }
                        </h3>
                    </ColouredShape>

                    <div className="video-wrapper my-2">
                        <div className={`posterImage ${this.state.playing ? "playing" : ""}`}
                             onClick={this.onVideoPlay}
                             onKeyDown={this.onVideoPlay}
                             tabIndex={0} 
                             role="button"
                             aria-label="button"
                        >
                            {this.props.otherImage ?
                                <GatsbyImage image={getImage(this.props.data.greatScottVideoTheatres)}
                                             alt="BTTF"
                                             title=""
                                             className="img-fluid"
                                />
                            :
                                <GatsbyImage image={getImage(this.props.data.greatScottVideo)}
                                             alt="BTTF"
                                             title=""
                                             className="img-fluid"
                                />
                            }
                            <img src={playIcon} alt="BTTF" title="" className="play-icon"/>
                        </div>
                        <img src={closeIcon} alt="" title="" className={`${this.state.playing ? "playing" : ""} close-icon`}
                             onClick={this.onVideoStop}
                             onKeyDown={this.onVideoStop}
                             tabIndex={0} role="button"
                             aria-command-name="Toggle video"/>
                        <ReactPlayer className="video-frame w-100"
                                     url={`https://www.youtube.com/embed/${this.props.videoId ? this.props.videoId : "rwU_HLUKv98"}?rel=0&amp;autoplay=0&modestbranding=1`}
                                     playing={this.state.playing}
                                     controls={true}
                        />
                    </div>

                    <StyledButton $hide className="my-2 my-md-4 mx-auto" $inverse  href={this.props.booking}>
                        GET TICKETS
                    </StyledButton>

                </Container>
            </Content>

        );
    }
}

export default (props) => (
    <StaticQuery
        query={graphql`
            query {
                greatScottVideo: file(relativePath: { eq: "BTTF-JUL23-video-poster-1200x789.jpg" }) {
                    childImageSharp {
                       gatsbyImageData(width: 1200)
                    }
                }
                greatScottVideoTheatres: file(relativePath: { eq: "HomePage/bttf-theatres-video-placeholder.jpg" }) {
                    childImageSharp {
                       gatsbyImageData(width: 1200)
                    }
                }
            }
		`}
        render={data => (
            <GreatScottBlock {...props} data={data}/>
        )}
    />
)
