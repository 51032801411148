import React, { useRef } from 'react';
import Slider from 'react-slick'
import styled from "styled-components";
import { media } from "utils/Media"
import {graphql, useStaticQuery} from "gatsby";
import nextImg from 'images/icons/next.svg'
import star from 'images/icons/star.svg'

const ReviewSliderStyles = styled.section`
    width: 100%;
    position: relative;
    background-color: #000;
    padding: 2rem 0 1rem 0 ;
    @media ${media.lg} {
        padding: 3rem 0 2rem 0;
    }
    .r-s {
        max-width: 1000px;
        width: 90%;
        margin: 0 auto;
        text-align: center;
        padding-top: 2rem;
        &--wrapper {
            position: relative;
            .prevBtn, .nextBtn {
                width: 30px;
                position: absolute;
                top: 31%;
                @media ${media.sm} {
                    width: 45px;
                }
            }
            .prevBtn {
                left: 0;
                transform: scaleX(-1);
                z-index: 5;
            }
            .nextBtn {
                right: 0;
            }
        }
    }
`
const Lines = styled.div`
    position: absolute;
    z-index: 1;
    background-color: #FF0000;
    height: 2px;
    width: 100vw;
    top: 1rem;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    left: 0;
    &::before, &::after {
        content: "";
        position: absolute;
        right: 0;
        z-index: 9;
        background-color: #FF7503;
        height: 2px;
        width: 98%;
        top: .4rem;
    }
   &::after {
        width: 96%;
        top: .85rem;
        background-color: #FFD200;
   }
    

`
const StarStyles = styled.section`
    width: 50%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        width: 30px;
        height: 30px;
        @media ${media.lg} {
            width: 45px;
            height: 45px;
        }
    }
`
const SlingleSliderStyles = styled.section`
    width: 75%;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    .s-s {
        h4 {
            color: #fff;
            margin: .5rem 0;
            font-family: Proxima Nova W01 Black;
            font-size: 1.5rem;
            font-weight: 900;
        }
        p {
            color: #EE7507;
            margin-bottom: .5rem;
        }
        .nostars-filler {
            opacity: 0;
            margin-top: -.85rem;
        }
        .review-filler {
            display: block;
            opacity: 0;
            /* padding-top: 1rem; */
            width: 100%;
        }
    }  
    @media ${media.sm} {
        width: 80%;
        .s-s {
            h4 {
                font-size: 1.5rem;
            }
            .review-filler {
                display: none;
            }
        
        }
    }
    @media ${media.md} {
        width: 80%;
        .s-s {
            h4 {
                font-size: 2rem;
            }
           
        }
    }
    @media ${media.xl} {
        width: 80%;
        .s-s {
            h4 {
                font-size: 2.2rem;
            }
           
        }
    }
    
`
const settings = {
    slidesToShow: 1,
    dots: false,
    arrows: false,
    swipe: true,
    swipeToSlide: true,
    adaptiveHeight: true,


}

function getStars(stars) {
    let content = [];
    for (let i = 1; i <= stars; i++) {
        content.push(<img key={i} src={star} alt="" title=""/>)
    }
    return content;
}

const ReviewSlider = () => {
    const { data } = useStaticQuery(graphql`
        query ReviewQueryNY {
              data: allNyreviewsJson {
                nodes {
                review
                company
                stars
                filler
                }
            }
        }
  `);
  const reviews = data.nodes;
  const sliderRef = useRef()
  const next = () => {
    sliderRef.current.slickNext()
  }
  const prev = () => {
    sliderRef.current.slickPrev()
  }
    return (
        <ReviewSliderStyles>
            <div className="r-s">
                <Lines />
                <div className="r-s--wrapper">
                    <PrevButton prev={prev} />
                    <Slider {...settings} ref={sliderRef}>
                        {
                            reviews.map((review, x) => (
                                <SingleSlider data={review} key={x} />
                            ))
                        }
                    </Slider>
                    <NextButton next={next} />
                </div>
            </div>
        </ReviewSliderStyles>
    )
}

export default ReviewSlider


const SingleSlider = ({ data }) => (
    <SlingleSliderStyles>
        <div className="s-s">
            {/* {
                data.filler ? <div className="review-filler"></div> : ''
            }
            {
                data.stars ? <StarStyles>{getStars(data.stars)}</StarStyles>
                 : <p className="nostars-filler"></p>
            } */}
            <h4>{`‘${data.review}’`}</h4>
            <p>{data.company}</p>
        </div>
    </SlingleSliderStyles>
)

const PrevButton = ({ prev }) => (
    <div className="prevBtn" onClick={() => prev()}>
        <img src={nextImg} alt="" />
    </div>
)
const NextButton = ({ next }) => (
    <div className="nextBtn" onClick={() => next()}>
        <img src={nextImg} alt="" />
    </div>
)
